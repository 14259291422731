import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from '../images/logo.svg'
import './404.scss';

export default function NotFound() {


    return(
        <div className="NotFound">
            <Logo className="icmlogo" />
            <h2>Not Found</h2>
            <p>There's been an error somewhere :(</p>
            <Link to="/">Go Home</Link>
        </div>
    )

}
