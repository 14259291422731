import { Formik, ErrorMessage, Field, Form } from 'formik';
import * as Yup from 'yup';
import React from 'react';

const StageSix = (props) => {

  const handleSubmit = (values) => {
    props.handleNext(values)
  };

  const validationSchema = Yup.object({
    creditorType: Yup.array().min(1, "Please check your creditor type.").max(1, "Only a single box can be checked.").required("Please select your creditor type."),
  });

  const checkboxOptions = [
    { key: 'Op1', value: 'Supplier' },
    { key: 'Op2', value: 'Subcontractor/Consultant' },
  ]

  return (
    <Formik
      initialValues={props.formData}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form>
          <div className="step">
            <h2>Creditor Type</h2>
            <label className='stepheader'>Please select your creditor type. This determines what documents are required for upload in the following sections.</label>
            <div className="stepform">
              <div className="checkbox-group">
                <Field name="creditorType">
                  {({ field }) => {
                    return checkboxOptions.map(option => {
                      return (
                        <div className='checkbox-outer'>
                          <React.Fragment key={option.key}>
                            <input
                              className='checkbox'
                              type="checkbox"
                              id={option.value}
                              {...field}
                              value={option.value}
                              checked={field.value.includes(option.value)}
                            />
                            <label className='labelCheckbox' htmlFor={option.key}>{option.value}</label>
                          </React.Fragment>
                        </div>
                      )
                    })
                  }}
                </Field>
                
              </div>
              <div className="errorstep6">
                <ErrorMessage component="div" name="creditorType" className="errormsg" />
              </div>
              
              <div className='creditorInfo'>
                <div>
                  <h3>Required documents for Supplier</h3>
                  <ul>
                    <li>Deposit Slip/First Page of Bank Statement for BSB & Account #</li>
                  </ul>
                </div>
                <div>
                <h3>Required documents for Subcontractor or Consultant</h3>
                  <ul>
                    <li>Deposit Slip/First Page of Bank Statement for BSB & Account #</li>
                    <li>Construction Licence (if applicable)</li>
                    <li>Public Liability CoC</li>
                    <li>Workcover CoC or Income protection CoC</li>
                    <li>Professional Indemnity CoC (if applicable)</li>
                  </ul>
                </div>
              </div>

            </div>
            
            <button className="stepbutton" type="submit">Next Step</button>
          </div>

        </Form>
      )
      }
    </Formik>
  )
}

export default StageSix;
