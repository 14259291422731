import axios from 'axios';
axios.defaults.withCredentials = true

export const checkAuth = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/authentication/validate`);
        return response.data;
    } catch(e) {
        return { authenticated: false };
    }
};

export const logoutUser = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/authentication/logout`);
        return response.data;
    } catch(e) {
        return false
    }
};
