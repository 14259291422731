import React, { useCallback, useState } from 'react'
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { DatePicker, Input } from 'antd';
import { handleFileUpload } from '../../api/fileAPI';
import { useParams } from 'react-router-dom';

const StageSeven = (props) => {

    const params = useParams()
    
    const [files, setFiles] = useState([]);
    const [fileUploaded, setFileUploaded] = useState(false);
    const [date, setDate] = useState(new Date());
    const [policyNumber, setPolicyNumber] = useState('');

    useEffect (() => {
        if (props.formData.creditorType[0] === "Supplier") {
            setFileUploaded(false)
        }
    }, [props.formData.creditorType])

    const handleSubmit = () => {
        if (files.length !== 0) {
            const formData = new FormData()
            formData.append("submissionID", params.formID)
            formData.append("expiryDate", date.toISOString())
            formData.append("policyNumber", policyNumber)
            formData.append("fileType", "Public Liability")
            for (let i = 0; i < files.length; i++) {
                formData.append("files", files[i]);
                if(i === files.length - 1) {
                    handleFileUpload(formData)
                }
            }
        }

        props.handleNext(props.formData)
    };

    const remove = (file) => {
        const newFiles = [...files];   
        newFiles.splice(newFiles.indexOf(file), 1);        
        setFiles(newFiles);     
        if (props.formData.creditorType[0] !== "Supplier" && newFiles.length === 0) {
            setFileUploaded(false)
        }         
    };

    const onDrop = useCallback(newFiles => {
        if (files.length === 0 && props.formData.creditorType[0] !== "Supplier") {
            setFileUploaded(prevFileUploaded => !prevFileUploaded)
        }
        if (newFiles) {
            setFiles(newFiles);
        }
    }, [files.length, props.formData.creditorType]);

    const { getRootProps, getInputProps} = useDropzone({maxFiles: 1, onDrop})

    const acceptedFileItems = files.map((file, i) => (
        <li key={file.path}>
            {file.name} : {file.size} bytes.
            <button className="removeButton" type="button" onClick={() => remove(i)}>Remove</button>
        </li>
    ));

    const disabledDate = (current) => {
        return current && current.valueOf() < Date.now();
    }

    const handlePolicyNumber = (e) => {
        setPolicyNumber(e.target.value)
    }

    const isFormValid = () => {
        if (props.formData.creditorType[0] !== "Supplier") {
            return fileUploaded && policyNumber !== '' && date
        }
        return true
    }

    return (
        <div className="fileupload-container">
            <h2>Document Upload</h2>
            <h4 className="fileinformation">Please use this to upload a document regarding your Public Liability CoC.</h4>
            <div className="fileupload">
                <div {...getRootProps({className: "dropzone"})}>
                    <input {...getInputProps()} />
                        <p>Drag & Drop files here, or click to select.</p>
                </div>
            </div>
            <div className="file-list">
                <h4>Files Attached:</h4>
                {acceptedFileItems.length ?
                <ul>{acceptedFileItems}</ul>
                : <p>There are currently no files attached.</p>
                }
            </div>

            <h4 className="fileinformation">Please select the date this policy expires.</h4>
            <DatePicker format={"DD/MM/YYYY"} disabledDate={disabledDate} onChange={(value) => setDate(value)}/>

            <h4 className="fileinformation">Please enter the reference/policy number.</h4>

            <Input value={policyNumber} name="policyNumber" onChange={handlePolicyNumber} />

            <button className="stepbutton" type="button" onClick={handleSubmit} disabled={!isFormValid()}>Next Step</button>

        </div> 
    )
}

export default StageSeven;
