import { Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';

const StageFive = (props) => {
    const handleSubmit = (values) => {
        props.handleNext(values)
    };

    return (
        <Formik validationSchema={Yup.object({
            bankName: Yup.string().required("Financial Institution Name is required."),
            bankBSB: Yup.string().required("BSB is required.").matches(/^[0-9]+$/, "Please enter a valid BSB.").min(6, "Please enter a valid BSB.").max(6, "BSB cannot be longer than 6 digits."),
            bankAccountName: Yup.string().required("Account Name is required."),
            bankAccountNumber: Yup.string().required("Account Number is required.").matches(/^[0-9]+$/, "Please enter a valid Account Number.").max(9, "Account number cannot be longer than 9 digits."),
        })} initialValues={props.formData} onSubmit={handleSubmit}>
            <Form>
                <div className="step">
                    <h2>Banking Details</h2>
                    <div className="stepform">
                        <label>Name of Financial Institution</label>
                        <Field type="text" name="bankName" label="Bank Name" />
                        <ErrorMessage component="div" name="bankName" className="errormsg"/>
                        <label>BSB</label>
                        <Field type="text" name="bankBSB" label="bankBSB"/>
                        <ErrorMessage component="div" name="bankBSB" className="errormsg"/>
                        <label>Account Name</label>
                        <Field type="text" name="bankAccountName" label="Account Name"/>
                        <ErrorMessage component="div" name="bankAccountName" className="errormsg"/>
                        <label>Account Number</label>
                        <Field type="text" name="bankAccountNumber" label="Account Number"/>
                        <ErrorMessage component="div" name="bankAccountNumber" className="errormsg"/>
                    </div>
                    <button className="stepbutton" type="submit">Next</button>
                </div>
            </Form>
        </Formik>
    )
}

export default StageFive;
