import { Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';


const StageFour = (props) => {
    const handleSubmit = (values) => {
        props.handleNext(values)
    };

    return (
        <Formik validationSchema={Yup.object({
            useSupplierAddressForAccount: Yup.boolean(),
            accountAddress: Yup.string().when("useSupplierAddressForAccount", {
                is: false,
                then: Yup.string().required("Account Address is Required.")
            }),
            accountTownSuburb: Yup.string().when("useSupplierAddressForAccount", {
                is: false,
                then: Yup.string().required("Account Town/Suburb is Required.")
            }),
            accountPostcode: Yup.string().when("useSupplierAddressForAccount", {
                is: false,
                then: Yup.string().required("Postcode is required.").matches(/^[0-9]+$/, "Please enter a valid postcode").min(4, "Please enter a valid postcode.").max(5, "Please enter a valid postcode."),
            }),
            accountState: Yup.string().when("useSupplierAddressForAccount", {
                is: false,
                then: Yup.string().required("Account State is Required.")
            }),
        })} initialValues={props.formData} onSubmit={handleSubmit}>
            {({
                values
            }) => (
                <Form>
                <div className="step">
                    <h2>Account Address</h2>
                    <div className="stepform">

                        <div>
                            <Field className="stepradio" type="checkbox" name="useSupplierAddressForAccount"/>
                            <label className="labelCheckbox">Use Supplier Address?</label>
                        </div>
                        <label>Address</label>
                        {values.useSupplierAddressForAccount
                        ? <Field type="text" disabled={true} value={props.formData.supplierAddress} name="accountAddress"/>
                        : <Field type="text" name="accountAddress" />
                        }
                        <ErrorMessage component="div" name="accountAddress" className="errormsg"/>

                        <label>Town / Suburb</label>
                        {values.useSupplierAddressForAccount
                        ? <Field type="text" disabled={true} value={props.formData.supplierTownSuburb} name="accountTownSuburb" />
                        : <Field type="text" name="accountTownSuburb"/>
                        }
                        <ErrorMessage component="div" name="accountTownSuburb" className="errormsg"/>

                        <label>Postcode</label>
                        {values.useSupplierAddressForAccount
                        ?  <Field type="text" disabled={true} value={props.formData.supplierPostcode} name="accountPostcode" />
                        :  <Field type="text" name="accountPostcode" />
                        }
                        <ErrorMessage component="div" name="accountPostcode" className="errormsg"/>

                        <label>State</label>
                        {values.useSupplierAddressForAccount
                        ? <Field type="text" disabled={true} value={props.formData.supplierState} name="accountState" />
                        : <Field type="text" name="accountState" label="Account State"/>
                        }
                        <ErrorMessage component="div" name="accountState" className="errormsg"/>
                    </div>
                    <button className="stepbutton" type="submit">Next</button>
                </div>
            </Form>
            )}
            
        </Formik>
    )
}

export default StageFour;
