import { Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';

const StageTwo = (props) => {
    const handleSubmit = (values) => {
        props.handleNext(values)
    };

    return (
        <Formik validationSchema={Yup.object({
            supplierAddress: Yup.string().required("Address is required."),
            supplierTownSuburb: Yup.string().required("Town or Suburb Name is required."),
            supplierPostcode: Yup.string().required("Postcode is required.").matches(/^[0-9]+$/, "Please enter a valid postcode").min(4, "Please enter a valid postcode.").max(5, "Please enter a valid postcode."),
            supplierState: Yup.string().required("State is required."),
        })} initialValues={props.formData} onSubmit={handleSubmit}>
            <Form>
                <div className="step">
                    <h2>Supplier Address</h2>
                    <div className="stepform">
                        <label>Address</label>
                        <Field type="text" name="supplierAddress" label="Supplier Address"/>
                        <ErrorMessage component="div" name="supplierAddress" className="errormsg"/>
                        <label>Town/Suburb</label>
                        <Field type="text" name="supplierTownSuburb" label="Supplier Town/Suburb"/>
                        <ErrorMessage component="div" name="supplierTownSuburb" className="errormsg"/>
                        <label>State</label>
                        <Field type="text" name="supplierState" label="Supplier State"/>
                        <ErrorMessage component="div" name="supplierState" className="errormsg"/>
                        <label>Postcode</label>
                        <Field type="text" name="supplierPostcode" label="Supplier Postcode"/>
                        <ErrorMessage component="div" name="supplierPostcode" className="errormsg"/>
                    </div>
                    <button className="stepbutton" type="submit">Next</button>
                </div>
            </Form>
        </Formik>
    )
}

export default StageTwo;
