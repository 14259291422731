import { Formik, Field, Form, ErrorMessage} from 'formik';
import * as Yup from 'yup';

const StageThree = (props) => {
    const handleSubmit = (values) => {
        props.handleNext(values)
    };

    return (
        <Formik validationSchema={Yup.object({
            accountContactName: Yup.string().required("Contact Name is required."),
            accountContactPhone: Yup.number().typeError("Contact Number must be numerical.").positive("Please enter a valid mobile number.").required("Contact Number is required."),
            accountContactEmail: Yup.string().email("Contact Email must be a valid email.").required("Account Email is required."),
            useAccountEmailForRemittance: Yup.boolean(),
            remittanceEmail: Yup.string().when("useAccountEmailForRemittance", {
                is: false,
                then: Yup.string().email("Please enter a valid email.").required("Remittance Email is Required.")
            }),
            purchaseOrderEmail: Yup.string().when("useAccountEmailForRemittance", {
                is: false,
                then: Yup.string().email("Please enter a valid email.").required("Purchase Order Email is Required.")
            }),

        })} initialValues={props.formData} onSubmit={handleSubmit}>
            {({
                values,
            }) => (
                <Form>
                <div className="step">
                    <h2>Account Contact</h2>
                    <div className="stepform">
                        <label>Contact Name</label>
                        <Field type="text" name="accountContactName" label="Account Contact Name"/>
                        <ErrorMessage component="div" name="accountContactName" className="errormsg"/>

                        <label>Telephone</label>
                        <Field type="text" name="accountContactPhone" label="Account Contact Phone"/>
                        <ErrorMessage component="div" name="accountContactPhone" className="errormsg"/>

                        <label>Contact Email Address</label>
                        <Field type="text" name="accountContactEmail" label="Account Contact Email"/>
                        <ErrorMessage component="div" name="accountContactEmail" className="errormsg"/>

                        <div className="stepradio">
                            <Field className="stepradio" type="checkbox" name="useAccountEmailForRemittance" label="Use Account Email?"/>
                            <label className="labelCheckbox">Use Contact Email address below?</label>
                        </div>

                        <label>Remittance Advice Email Address</label>
                        {values.useAccountEmailForRemittance
                        ? <Field type="text" name="remittanceEmail" disabled={true} value={values.accountContactEmail}/>
                        : <Field type="text" name="remittanceEmail" label="Remittance Email"/>
                        }
                        <ErrorMessage component="div" name="remittanceEmail" className="errormsg"/>

                        <label>Purchase Order (PO) Email Address</label>
                        {values.useAccountEmailForRemittance
                        ? <Field type="text" name="purchaseOrderEmail" disabled={true} value={values.accountContactEmail}/>
                        : <Field type="text" name="purchaseOrderEmail" />
                        }
                        <ErrorMessage component="div" name="purchaseOrderEmail" className="errormsg"/>
                    </div>
                    <button className="stepbutton" type="submit">Next</button>
                </div>
            </Form>
            )}
            
        </Formik>
    )
}

export default StageThree;
