import React from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as Logo } from '../images/logo.svg'
import { ReactComponent as Tick } from '../images/tick.svg'
import './Success.scss';


export default function Success () {

    const { formID } = useParams();

    return (
        <div className="Success">
            <Logo className="icmlogo"/>
            <h2>Submission Recieved</h2>
            <Tick className="tick"/>
            <h3>Reference Number: {formID}</h3>
            <p>Thank you, your submission has been received. Please allow 2-5 business days for a response.</p>
            <p>You can now close this window.</p>
        </div>
        
    )
}
